.sf-nav {
    position: relative;
    z-index: 10;
}

@include media-breakpoint-down(sm) {
    .sf-nav-outer {
        &.has-sub-nav {
            height: 120px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .sf-nav-brand {
        svg {
            height: 50px;
            width: auto;
        }
    }
}

@include media-breakpoint-up(xl) {
    .sf-nav-sub-list {
        margin-left: -1.5rem;
    }
}

@include media-breakpoint-up(lg) {
    .sf-nav-outer {
        height: 120px;
        z-index: 100;

        &.has-sub-nav {
            height: 200px;
        }
    }

    .sf-nav-wrapper {
        height: 120px;
        transition: 0.2s ease;
    }

    .sf-nav-sticky {
        margin-top: -4rem;
        transition: 0.2s ease;
        height: 120px;
    }



    .sf-nav-open,
    .sf-nav-sub-open {
        overflow: auto;
    }

    .sf-nav {
        &-item {
            &.open {
                .sf-nav-sub-list {
                    border-top: 6px solid $nav-grey-color;
                }
            }
        }
    }

}

.sf-nav-link {
    letter-spacing: 0.8px;

    @include media-breakpoint-only(md) {
        font-size: 1.4rem;
        padding: 1.5rem 1rem;
    }

    @include media-breakpoint-down(lg) {
        min-height: 50px;
    }
}

.sf-nav-toolbar {
    height: 40px;
    border-bottom: 1px solid $sfnav-toolbar-border-color;

    &-link {
        font-family: $font-primary-bold;
        font-size: 1.3rem;
        font-weight: 400;
        padding-left: 2.5rem;
        letter-spacing: 1.3px;
        color: $nav-grey-color;
        @include media-breakpoint-down(lg) {
            padding-left: 0.5rem;
        }

        &.active,
        &:hover,
        &:focus {
            text-decoration: none;
            color: $primary;
        }
    }

    &-current-user {
        font-family: $font-primary-bold;
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: 0.65px;

        strong {
            font-family: $font-primary-bold;
            font-weight: 400;
            color: $primary;
        }
    }

    &-inner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 40px;

        .fas,
        .far {
            display: inline-block;
            width: 20px;
        }
    }
}

.sf-nav-sub-link {
    letter-spacing: 0.8px;

    strong {
        padding-left: 0.5rem;
        color: $primary;
    }
}

@include media-breakpoint-up(lg) {
    .sf-nav-brand {
        justify-content: center;
        align-items: center;
        @include media-breakpoint-up(md) {
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
        }

        svg,
        img {
            height: 50px;
        }
    }
}
