$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 900px,
        xl: 900px
) !default;

// Bootstrap overrides
$body-bg: #F2F2F3;
$light: #AEB0B3;
$purple: #6D0E71;
$white: #fff;
$black: #000;
$primary: $purple;
$font-family-sans-serif: Arial, Helvetica, sans-serif;
$font-primary: $font-family-sans-serif;
$font-primary-bold: $font-family-sans-serif;


// sf-nav overrides
$nav-grey-color: $light;
$sfnav-lg-breakpoint-min: 992px;
$sfnav-xs-breakpoint-max: 991px;
$sfnav-toolbar-border-color: #e7e6e6;
$sfnav-background-lg: transparent;
$sfnav-background-xs: $white;
$sfnav-base-font-weight: 400;
$sfnav-base-link-background-active: $white;
$sfnav-base-link-color-active: $primary;
$sfnav-base-link-font-weight: 400;
$sfnav-base-link-padding: 0.7rem;
$sfnav-base-link-text-transform: none;
$sfnav-base-padding: 0 1.5rem;
$sfnav-base-sub-font-size: 1rem;
$sfnav-base-sub-font-weight: 400;
$sfnav-base-sub-link-background-active: $light;
$sfnav-base-sub-link-background: $white;
$sfnav-base-sub-link-border-active: 1px solid $light;
$sfnav-base-sub-link-border: $sfnav-base-sub-link-border-active;
$sfnav-base-sub-link-color-active: $primary;
$sfnav-base-sub-link-color: $white;
$sfnav-base-sub-link-padding: 10px 1rem;
$sfnav-base-sub-link-width: 300px;
$sfnav-color: $white;
$sfnav-flex-direction: row-reverse;
$sfnav-font-family: $font-family-sans-serif;
$sfnav-font-size: 1rem;
$sfnav-height-lg: 80px;
$sfnav-sidebar-icon-active: '\f053';
$sfnav-sidebar-icon: '\f054';
$sfnav-sidebar-link-background-active: $white;
$sfnav-sidebar-link-border: 1px solid $white;
$sfnav-sidebar-link-color-active: $white;
$sfnav-sidebar-link-font-size-xs: 1.6rem;
$sfnav-sidebar-link-font-weight-xs: 700;
$sfnav-sidebar-link-font-xs: $font-family-sans-serif;
$sfnav-sidebar-link-padding: 1.5rem;
$sfnav-sticky-height: 80px;
$sfnav-shadow: 0 0 20px rgba(34, 30, 32, 0.3);
$sfnav-width: 1140px;
$sfNav-sticky-animation: 0.2s ease;
$sfnav-base-link-padding-last: $sfnav-base-sub-link-padding;
