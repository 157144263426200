@import 'variables';
@import 'vendor/bootstrap';

@import "components/_sf-nav.scss";

@import '../../../../node_modules/sf-frontend-base/src/nav/scss/sfnav';
@import '../../../../node_modules/sf-frontend-base/src/nav/scss/lg/default/default';
@import '../../../../node_modules/sf-frontend-base/src/nav/scss/xs/sidebar/sidebar';

body {
  background-color: $body-bg;
}

.content-container {
  background-color: $white;
}

h1 {
  color: $purple;
}

h2,h3,h4,h5,h6 {
  color: $gray-600;
}

h1 {
  &.h1-medium {
    font-size: 24px;
  }
}

.sf-nav-list {
  background-color: $gray-500;
  padding: 0 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.nav-container {
  background-color: #fff;
}

.sub-footer {
  hr { border-color: $gray-500; }
  color: $purple;
}

.footer {
  font-size: .8em;

  .copyright {
    color: $purple;
    display: block;
  }
 text-align: center;
}

.sweet-field {
  display: none;
}